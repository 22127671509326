import { Component, OnDestroy,Inject, OnInit,PLATFORM_ID } from '@angular/core';
import {AppConstants} from '../../../shared/constants/app-constants';
import {ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';

import {DatePipe} from '@angular/common';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Highlight} from '../../../shared/highlights/highlight';
import {ExportModel} from '../../../data/model/ExportModel/export-model';
import {ExportConstants} from '../../../shared/constants/export-constants';
import {debounceTime} from 'rxjs/operators';
import {Subscription, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import { BaseListComponent} from '../../../abstract/base-list.component';
import { ErrorConstants} from '../../../shared/constants/error-constants';

import { DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import { MatDialog} from '@angular/material/dialog';
import { FilterModel } from '../../../data/model/filteModel/filter-model';
import { FilterConstants } from '../../../shared/constants/filter-constants';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { MessageDialogComponent } from '../../../dialogs/message-dialog/message-dialog.component';

@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.css'],
  providers: [DataService]
})
export class CampaignSummaryComponent extends BaseListComponent implements OnInit, OnDestroy {
  displayedColumns = ['id', 'transactiondate', 'type', 'status', 'customer', 'paymentinformation', 'amount'];
  listCallFlag: boolean;
  exportData: Object;
  from_date: any;
  to_date: any;
  n = 0;
  filters: string;
  filter:FilterModel[] = FilterConstants.campaignSummaryFilter;
  private sortDirection: string;
  today: Date = new Date();
  public searchHits: Subject<null>;
  public searchText: string;
  showMeta = false;
  chargeList: ResponseWrapper<any>;
  metadata:any;
  listOfCampaigns: any;
  protected getPayeeUrl: string;
  dataService: DataService;
  urlwithLimit: string;
  _service_name = '/campaigns';
  _service_name_campaign = '/campaigns';
  campaignApiPostUrl: string;
  redirect_link: string;
  actionActive: boolean;
  hasPayees: boolean;
  redirect_link_details: string;

  constructor(private _route: ActivatedRoute, router: Router,  private datePipe: DatePipe,    private dialog: MatDialog, private http:HttpClient, dataManager: DataManagerService, @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any, snackBar: MatSnackBar,  dataService: DataService) {
    super();
    this.showMeta = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)) ?
    JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)).display_transactional_metadata : false;
    this.errorMsg = null;
    this.searchHits = new Subject();
    this.router = router;
    this.dataManager = dataManager;
    this.chargeList = new ResponseWrapper<any>();
    this.loadFlag =false;
    this.snackBar = snackBar;
    this.limit = 10;
    this.getListUrl = AppConstants.BASE_URL + this._service_name;
    this.campaignApiPostUrl = AppConstants.BASE_URL + this._service_name_campaign;
    this.dataService = dataService;
    this.http = http;
    this.urlwithLimit = this.getListUrl  + '?limit=' +this.limit  + '&page=1'+ '&orderBy=updated_at&sortedBy=desc'+'&include=payees';
    this.filter_storage_key = FilterConstants.CAMPAIGN_SUMMARY_FILTER_STORAGE_KEY;
    this.count_storage_key = FilterConstants.CAMPAIGN_SUMMARY_FILTER_COUNT_STORAGE_KEY;
    this.redirect_link = '/merchant-bridge/edit-campaign';
    this.actionActive = false;
    this.hasPayees = true;
    this.redirect_link_details = '/merchant-bridge/campaign-details';
    this.filterSubscription = this.dataManager.filterUrl.subscribe(
      (url) => {
        this.filterPostFix = url;
        console.log(this.filterPostFix);
        this.dataManager.prev_url = url;
        this.is_filter = true;
        this.setUrl();
      },
      (err) => console.log(err)
    );
   }
  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.searchHits.pipe(debounceTime(600), ).subscribe((res) => {
      if (this.searchText) {
        // this.setUrl();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(FilterConstants.CAMPAIGN_SUMMARY_FILTER_STORAGE_KEY)
        && !this.localStorage.getItem(FilterConstants.CAMPAIGN_SUMMARY_FILTER_COUNT_STORAGE_KEY)) {
        this.setUrl();
      } else  {
        // this.setUrl();
      }
    }
    this.dataService.getPayeeData(this.urlwithLimit).subscribe((res) => {
      try {
             this.getListRes(res);

      } catch (error) {

        console.log('campaign error',error);
        this.getListErr(error);
      }

    })
  }

  createNewCampaign() {
    this.router.navigate(['../merchant-bridge/add-new-campaign'])
   }
 protected getListErr(error) {
    this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.createInvoiceErrorMsg);
    this.loadFlag = false;
  }
  protected getListRes(res) {
    this.loadFlag = false;
    this.listOfCampaigns = res.data;
    this.metadata = res.meta.pagination;
    if (res.data.length === 0) {
      this.errorMsg = ErrorConstants.campaignsErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
  }
  paginate(paginateExtension) {
    this.getList(paginateExtension);
    this.loadFlag = true;
  }

  checkdate() {
    if (!this.isNull(this.from_date) && this.from_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  checkdate2() {

    if (!this.isNull(this.to_date) && this.to_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  highlight(value) {
    return Highlight.highlight(value, this.searchText);
  }

  datereport() {
    if ((this.from_date && this.from_date.toString() === 'Invalid Date') || (this.to_date && this.to_date.toString() === 'Invalid Date')) {
      this.showSnackBar('Enter valid date.', 'Ok', 3000);
    } else if ((this.isNullOrUndefined(this.from_date)) || this.isNullOrUndefined(this.to_date)) {
      if ((!this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date)))
        || (this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date)))) {
        this.from = this.from_date;
        this.to = this.to_date;
        // this.setUrl();
      } else {
        return;
      }
    } else if (this.from_date > this.to_date) {
      this.showSnackBar('From date should not exceed ' + this.datePipe.transform(this.to_date, 'MM/dd/yyyy') + '.', 'Ok', 3000);
    } else {
      this.from = (this.isNullOrUndefined(this.from_date) ? '' : (this.datePipe.transform(this.from_date, 'yyyy-MM-dd')));
      this.to = (this.isNullOrUndefined(this.to_date) ? '' : (this.datePipe.transform(this.to_date, 'yyyy-MM-dd')));
      // this.setUrl();
    }
  }
  deleteCampaign(id: string) {

    if (this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Campaign', message: 'This will permanently remove the campaign. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});

    dialog.afterClosed().subscribe(value => {
      if (value) {
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CAMPAIGNS+'/'+id;

        this.deleteCampaignApi(url);
        this.loadFlag = false;
        this.getList(this.getListUrl);
        this.paginate(this.getListUrl);
      }
    });
  }

  deleteCampaignApi(url){
    this.dataManager.deleteRequest(url)
        .subscribe((res)=>{
            this.passDeleteResponse(res);
            this.dataManager.appLoaderFlag(true);
        },
        error=>{
            this.getListErr(error.error);
            this.networkErrorHandler(error);
        })

}
protected passDeleteResponse(response: any) {

}
}
