import { Component, DoCheck, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditCampaignAction } from './edit-payee.action';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from '../../../shared/constants/app-constants';
import { MessageDialogComponent } from '../../../dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-payee',
  templateUrl: './edit-payee.component.html',
  styleUrls: ['./edit-payee.component.css']
})
export class EditPayeeComponent extends EditCampaignAction implements OnInit, DoCheck {
pageName:string;
payeeId:string;
topErrorMessage:string;
editPayee:FormGroup;
getPayeeUrl:string;
keys:String[];
keys1:String[];
topErrorMsg:string[];
errorMsg:string='';
errorMsgFlag:boolean;
buttonText:string;
typePayeePercentage:boolean=true;
typePayeeAmount:boolean=false;
selectedPayeeType:string;
editCampaignPostUrl:string;
actionActive:boolean;


  constructor(router:Router,route:ActivatedRoute , private fb:FormBuilder,dataManager:DataManagerService,snackBar:MatSnackBar,private dialog: MatDialog) {
    super();
    this.router = router;
    this.route = route;
    this.getPayeeUrl =AppConstants.BASE_URL+AppConstants.SERVICE_NAME_PAYEES;
    this.dataManager = dataManager;
    this.snackBar = snackBar;
    this.buttonText = 'Edit Payee';
    this.editCampaignPostUrl = AppConstants.BASE_URL+AppConstants.SERVICE_NAME_PAYEES;
    this.actionActive = false;
   }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params=>{
        this.pageName = params.page;
        this.campaignId = params.campaignId;
      })

      this.route.paramMap
      .subscribe(params=>{
        this.payeeId = params.get('id')
        this.getPayeeUrl=this.getPayeeUrl+'/'+this.payeeId;
        this.editCampaignPostUrl = this.editCampaignPostUrl+'/'+this.payeeId;
      })

      this.editPayee=this.fb.group({
        first_name: ['',[Validators.required,Validators.minLength(3)]],
        last_name: ['',[Validators.required,Validators.minLength(3)]],
        email: ['',[Validators.required,Validators.email]],
        type: ['',[Validators.required]],
        percent: ['0'],
        fixed_amount: ['0'],
        dashboard_access: ['',[Validators.required,,Validators.minLength(1)]]

      })
      this.getPayeeApi(this.getPayeeUrl);
  }
  ngDoCheck(): void {
    if (this.selectedPayeeType==='percent_and_fixed') {

    }
  }

  submitPayeeEdited() {
    let bodyPost = this.editPayee.value;
    this.editPayeePost(bodyPost);
  }

passGetPayeesResponse(response) {
  this.editPayee.patchValue({
    first_name:response.data.first_name,
    last_name:response.data.last_name,
    email:response.data.email,
    type:response.data.type,
    percent:response.data.percent,
    fixed_amount:response.data.fixed_amount,
    dashboard_access:response.data.dashboard_access===true?'1':'0'
  })
 if (this.editPayee.value.type==='percent_and_fixed') {
  this.typePayeePercentage=true;
      this.typePayeeAmount=true;
 }
  this.showSnackBar('Payee Fetched Successfully','OK',3000);
}
  cancelPayeeEdited() {
    this.backToPage();
  }

  backToPage() {
    if (this.campaignId ===undefined || this.campaignId ===null) {
    this.campaignId = '';

}
      this.router.navigate(['merchant-bridge/'+this.pageName +'/'+ this.campaignId] );

  }
  onSelected(value){
    this.typePayeePercentage=false;
      this.typePayeeAmount=false;
    if (value==='percent') {
      this.typePayeePercentage=true;
      this.editPayee.controls['fixed_amount'].clearValidators();
      this.editPayee.controls['percent'].setValidators([Validators.required,Validators.minLength(1),Validators.max(100)]);
    } else if(value==='fixed'){
      this.typePayeeAmount=true;
      this.editPayee.controls['percent'].clearValidators();
      this.editPayee.controls['fixed_amount'].setValidators([Validators.required,Validators.minLength(1)]);
    } else {
      this.typePayeePercentage=true;
      this.typePayeeAmount=true;
      this.editPayee.controls['fixed_amount'].clearValidators();
      this.editPayee.controls['percent'].clearValidators();
      this.editPayee.controls['percent'].setValidators([Validators.required,Validators.minLength(1),Validators.max(100)]);
      this.editPayee.controls['fixed_amount'].setValidators([Validators.required,Validators.minLength(1)]);

    }
  }
  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(2);
  }
  _keyPress(event: any) {
    const keypressed = event.which || event.keyCode;
    console.log(keypressed);
    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || keypressed === 46 // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onlyNumber(event: any) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  deletePayee(id: string) {
    if (id === undefined || id === null || id === ''){
      this.payeeId =id ;
    }

    if (this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Payee', message: 'This will permanently remove the payee. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_PAYEES+'/'+id;

        this.deletePayeeApi(url);
        this.loadFlag = true;
        this.router.navigate(['merchant-bridge/edit-campaign'+'/'+this.campaignId]);


      }
    });

  }
  print(data){
    console.log(data);
  }
  protected passEditResponse(response: any) {
    this.showSnackBar('Payee Edited Successfully','OK',3000);
  }
  protected passGetResponse (response: any) {

  }
  passDeleteResponse(response: any) {}
  passError(error: any) {
    this.showSnackBar('Couldn\'t get payee info.', 'Ok', 3000);
    this.errorMsg = error.message;
    this.topErrorMsg = error.errors;
    this.keys = Object.keys(this.topErrorMsg);
    this.errorMsgFlag = true;
    this.keys1 = Object.values(this.topErrorMsg);
    console.log(this.keys, 'asutosh',this.topErrorMsg);
    let i;
    let j;
    const name = ['first_name', 'last_name', 'percent', 'campaign_id', 'status', 'type', 'email', 'fixed_amount'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
  protected getList(url: any): void {

  }
  protected getListRes(res: any) {

  }
  protected getListErr(err: any) {
    this.showSnackBar('Error in fetching Payee','OK',3000);
  }
}
