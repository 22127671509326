import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-session-popup',
  templateUrl: './session-popup.component.html',
  styleUrls: ['./session-popup.component.css']
})

export class SessionPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SessionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
