import {
  Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {FilterModel} from '../../data/model/filteModel/filter-model';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {AppConstants} from '../constants/app-constants';
import {window} from 'rxjs/operators';

const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

@Component({
  selector: 'app-filter',
templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  animations: [
    trigger('expend', [
      state('true', style({
        height: '*',
        display: 'visible'
      })),
      state('false', style({
        height: '0px',
        display: 'none'
      })),
      transition('open => close', animate('0.5s ease-in-out')),
      transition('close => open', animate('0.5s ease-in-out'))
    ]),
  ]
})

export class FilterComponent implements OnInit, OnChanges {
  @Input() filter: FilterModel[];
  @Input() count_storage_key: string;
  @Input() filter_storage_key: string;
  @Input() campaignName: string;
  @Output() campaignNameChange = new EventEmitter<string>();
  calenderFlag: boolean;
  doneFlag: boolean;
  Url = '';
  check: boolean;
  heading: string;
  count: number;
  Showcount: number;
  showHide: boolean;
  countFlag: boolean;

  constructor(public dataManager: DataManagerService, private datePipe: DatePipe,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any) {

    this.heading = 'Date';
    this.count = 0;
    this.Showcount = 0;
    this.showHide = false;
  }


  ngOnInit() {
    if (this.Showcount != 1 && this.countFlag == false) {
      this.setFilters();
    }

  }

  setFilters(is_screenChange = null) {
    console.log('original filter', this.filter);
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem(this.filter_storage_key)) {
        console.log(this.localStorage.getItem(this.filter_storage_key));
        console.log('filter count', this.localStorage.getItem(this.count_storage_key));
        console.log('original filter', this.filter);
        if (this.filter) {

          // the app is getting local storage data but not really needs it. This is regarding ticket 2110.Adding Fiters to Subscription;
          this.filter = JSON.parse(this.localStorage.getItem(this.filter_storage_key));
          console.log('original filter', this.filter);
          this.count = Number(this.localStorage.getItem(this.count_storage_key));
          console.log('assigned count', this.count);
          (this.count > 0) ? this.countFlag = true : this.countFlag = false;
          console.log('count flag', this.countFlag);
          console.log('new assigned', this.filter);
          this.filter.forEach(value => {
            if (value.componentType === 'date') {
              if (value.date != null) {
                value.date = new Date(value.date);
              }
              if (value.dateFrom != null) {
                value.dateFrom = new Date(value.dateFrom);
              }
              if (value.dateTo != null) {
                value.dateTo = new Date(value.dateTo);
              }
            }
          });
          this.done(null);
        }
      } else {
        this.clear();
        this.apiCalls();
        this.Showcount = 1;
        this.countFlag = false;
        this.count = 0;
      }
    } else {
      this.clear();
      this.apiCalls();
      this.countFlag = false;
      this.count = 0;
    }
  }

  apiCalls() {
    for (const filter of this.filter) {
      if (filter.componentType === 'list') {
        if (filter.fieldKey === 'plan') {
          this.makeAPiCall(AppConstants.BASE_URL + AppConstants.SERVICE_NAME_PLANS + '?limit=100', filter);
        }
      }
    }
  }

  Toggle() {
    this.showHide = !this.showHide;
  }

  Show() {
    if (this.showHide === true) {
      const outsideClickListener = (event) => {
        if (this.showHide === true) {
          if (!document.getElementById('button-basic').contains(event.target) && this.showHide === true) { // or use: event.target.closest(selector) === null
            if (!document.getElementById('dropdown-menu').contains(event.target) && this.showHide === true) {
              this.showHide = false;
              removeClickListener();
            }
          }
        } else {
          removeClickListener();
        }
      };
      const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
        this.showHide = true;
      };
      // document.addEventListener('click', outsideClickListener);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showHide = false;
    console.log('filter component changes lifecycle hook set', changes);
    this.count = 0;
    this.setFilters();
  }

  makeAPiCall(url, filter) {
    this.dataManager.getRequest(url, 0)
      .subscribe(
        (response) => {
          console.log(response);
          this.filter.find(x => x.fieldKey === filter.fieldKey).options = [];
          for (const plan of response.data) {
            this.filter.find(x => x.fieldKey === filter.fieldKey).options.push({
              key: plan.plan_id,
              value: plan.name ? plan.name : plan.plan_id
            });
          }
        }
      );
  }


  done(ele = null) {
    let count = 0;
    this.doneFlag = true;
    ele !== null ? ele.hide() : console.log('filter set from local');
    this.Url = '';
    for (const fil of this.filter) {
      fil.checked === true ? count++ : console.log(fil.checked);
      this.Url = this.Url + (fil.checked ? (this.createUrl(fil) !== '' ? ('&' + this.createUrl(fil)) : '') : '');
      console.log('Url', this.Url);
    }
    this.count = count;
    this.countFlag = this.count > 0 ? true : false;
    console.log('convert filter to string', this.count, JSON.stringify(this.filter));
    this.localStorage.setItem(this.filter_storage_key, JSON.stringify(this.filter));
    this.localStorage.setItem(this.count_storage_key, this.count);
    this.dataManager.setFilterUrl(this.Url);
  }

  createUrl(arg: FilterModel) {
    switch (arg.componentType) {
      case 'text' :
        return (arg.fieldKey);
      case 'input' :
        if (arg.fieldKey === 'invoice_number' || arg.fieldKey === 'c39' || arg.fieldKey === 'c13' || arg.fieldKey === 'Primary_Account_Number') {
          return (arg.fieldKey + '[like]=' + '*' + arg.inputValue + '*');
        }
        if (arg.fieldKey==='search=name:') {
          return (arg.fieldKey  + encodeURIComponent(arg.inputValue));
        }
        return (arg.fieldKey + '[eq]=' + encodeURIComponent(arg.inputValue)); // add encodeURIComponent to transfer safety user input
      case 'list' :
        if (arg.fieldKey === 'card') {
          return (arg.fieldKey + '[active]=' + arg.selectedOption);
        } else if (arg.fieldKey === 'plan') {
          return (arg.fieldKey + '=' + arg.selectedOption);
        }
        return (arg.fieldKey + '[eq]=' + arg.selectedOption);
      case 'date' :
        if (arg.selectedOption === '[intl]=') {
          return (arg.dayCount ? (arg.fieldKey + arg.selectedOption + arg.dayCount) : '');
        } else if (arg.selectedOption === 'gt' || arg.selectedOption === 'lt' || arg.selectedOption === 'lte') {
          return (arg.date ? (arg.fieldKey + '[' + arg.selectedOption + ']=' + this.datePipe.transform(arg.date, 'yyyy/MM/dd')) : '');
        } else if (arg.selectedOption === 'between') {
          return ((arg.dateFrom && arg.dateTo) ? (arg.fieldKey + '[gte]=' + this.datePipe.transform(arg.dateFrom, 'yyyy/MM/dd')
            + '&' + arg.fieldKey + '[lte]=' + this.datePipe.transform(arg.dateTo, 'yyyy/MM/dd')) : '');
        } else if (arg.selectedOption === 'equals') {
          return (arg.date ? (arg.fieldKey + '[eq]=' + this.datePipe.transform(arg.date, 'yyyy/MM/dd')) : '');
        }
        break;
      case 'number' :
        if ((arg.fieldKey === 'max_redemptions') || (arg.fieldKey === 'percent_off')) {
          if (arg.selectedOption === 'between') {
            return ((arg.numberFrom && arg.numberTo) ? (arg.fieldKey + '[lte]=' + (arg.numberTo)
              + '&' + arg.fieldKey + '[gte]=' + (arg.numberFrom)) : '');
          } else if (arg.selectedOption === 'equals') {
            return (arg.number ? (arg.fieldKey + '[eq]=' + (arg.number)) : '');
          } else if (arg.selectedOption === 'greaterThan') {
            return (arg.number ? (arg.fieldKey + '[gt]=' + (arg.number)) : '');
          } else if (arg.selectedOption === 'lessThan') {
            return (arg.number ? (arg.fieldKey + '[lt]=' + (arg.number)) : '');
          }
        } else {
          if (arg.selectedOption === 'between') {
            return (arg.fieldKey + '[lte]=' + (arg.numberTo * 100)
              + '&' + arg.fieldKey + '[gte]=' + (arg.numberFrom * 100));
            //  return (( arg.numberFrom && arg.numberTo) ? (arg.fieldKey + '[lte]=' + (arg.numberTo * 100)
            //  + '&' + arg.fieldKey + '[gte]=' + (arg.numberFrom * 100)) : '');
          } else if (arg.selectedOption === 'equals') {
            return (arg.number ? (arg.fieldKey + '[eq]=' + parseInt(String((arg.number * 100)), 10)) : (arg.fieldKey + '[eq]=0'));
          } else if (arg.selectedOption === 'greaterThan') {
            return (arg.number ? (arg.fieldKey + '[gt]=' + parseInt(String((arg.number * 100)), 10)) : (arg.fieldKey + '[gt]=0'));
          } else if (arg.selectedOption === 'lessThan') {
            return (arg.number ? (arg.fieldKey + '[lt]=' + parseInt(String((arg.number * 100)), 10)) : (arg.fieldKey + '[lt]=0'));
          }
        }

    }
  }

  clear() {
    console.log('clear');
    for (const fil of this.filter) {
      fil.date = fil.inputValue = fil.numberFrom = fil.numberTo = fil.number = fil.dateFrom = fil.dateTo = fil.dayCount = null;
      fil.checked = false;
      fil.selectedSubOption = fil.subOption ? fil.subOption[0].key : null;
      if (fil.options) {
        if (fil.options.length > 0) {
          fil.selectedOption = fil.options ? fil.options[0].key : null;
        }
      }
    }
    this.campaignName = '';
    this.campaignNameChange.emit(this.campaignName);

  }

  onDropHide(arg) {
    if (this.calenderFlag) {
      this.calenderFlag = false;
      arg.show();
    } else if (this.doneFlag) {
      this.doneFlag = false;
      arg.hide();
    }
  }

  setCalanderFlag() {
    this.calenderFlag = true;
  }

  filterCount(arg) {
    console.log(arg);
    // this.count = arg ? (this.count + 1) : (this.count - 1);
  }
}


