import { Component, OnDestroy,Inject, OnInit,PLATFORM_ID } from '@angular/core';
import {AppConstants} from '../../../shared/constants/app-constants';
import {ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';

import {DatePipe} from '@angular/common';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Highlight} from '../../../shared/highlights/highlight';
import {ExportModel} from '../../../data/model/ExportModel/export-model';
import {ExportConstants} from '../../../shared/constants/export-constants';
import {debounceTime} from 'rxjs/operators';
import {Subscription, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import { BaseListComponent} from '../../../abstract/base-list.component';
import { ErrorConstants} from '../../../shared/constants/error-constants';

import { DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import { MatDialog} from '@angular/material/dialog';
import { FilterModel } from '../../../data/model/filteModel/filter-model';
import { FilterConstants } from '../../../shared/constants/filter-constants';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { PayeeSummaryAction } from './payee-summary.action';
import { MessageDialogComponent } from '../../../dialogs/message-dialog/message-dialog.component';


@Component({
  selector: 'app-payee-summary',
  templateUrl: './payee-summary.component.html',
  styleUrls: ['./payee-summary.component.css'],
  providers: [ DataService]
})
export class PayeeSummaryComponent extends PayeeSummaryAction
 implements OnInit, OnDestroy {
  displayedColumns = ['id', 'transactiondate', 'type', 'status', 'customer', 'paymentinformation', 'amount'];
  listCallFlag: boolean;
  exportData: Object;
  from_date: any;
  to_date: any;
  n = 0;
  filters: string;
  filter:FilterModel[] = FilterConstants.payeeSummaryFilter;
  private sortDirection: string;
  today: Date = new Date();
  public searchHits: Subject<null>;
  _router: Router;
  public searchText: string;
  showMeta = false;
  chargeList: ResponseWrapper<any>;
  metadata:any;
  listOfPayees: any;
  protected getPayeeUrl: string;
  dataService: DataService;
  urlwithLimit: string;
  _service_name = '/payees';
  _service_name_campaign = '/campaigns';
  campaignApiPostUrl: string;
  hasCampaigns: boolean;
  redirect_link: string;
  redirect_link_edit: string;
  payeeId: string;
  actionActive: boolean;
  campaignName: string;
  paginationSimulationPayeesFromCampaignsApi: any;

  constructor(private _route: ActivatedRoute, router: Router,  private datePipe: DatePipe,    private dialog: MatDialog, private http:HttpClient, dataManager: DataManagerService, @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any, snackBar: MatSnackBar,  dataService: DataService) {
    super();
    this.showMeta = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)) ?
    JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)).display_transactional_metadata : false;
    this.errorMsg = null;
    this.searchHits = new Subject();
    this._router = router;
    this.router = router;
    this.dataManager = dataManager;
    this.chargeList = new ResponseWrapper<any>();
    // this.dataService = new ResponseWrapper<any>();
    this.loadFlag =false;
    this.snackBar = snackBar;
    this.limit = 10;
    this.getListUrl = AppConstants.BASE_URL + this._service_name;
    this.campaignApiPostUrl = AppConstants.BASE_URL + this._service_name_campaign;
    this.dataService = dataService;
    this.http = http;

    this.urlwithLimit = this.getListUrl  + '?limit=' +this.limit +'&include=campaigns' + '&page=1'+ '&orderBy=updated_at&sortedBy=desc' ;
    this.filter_storage_key = FilterConstants.PAYEE_SUMMARY_FILTER_STORAGE_KEY;
    this.count_storage_key = FilterConstants.PAYEE_SUMMARY_FILTER_COUNT_STORAGE_KEY;
    this.hasCampaigns = true;
    this.redirect_link = '/merchant-bridge/payee-details';
    this.redirect_link_edit = '/merchant-bridge/edit-payee';
    this.actionActive = false;
    this.campaignName = '';
    this.filterSubscription = this.dataManager.filterUrl.subscribe(
      (url) => {

        //if you want to send 2 difeerent api calls for payees and campaigns add code with mergeMap() of the two api calls below
        // destruct the url and check if it has campaigns or payees and set the url accordingly
        // and send the get calls to the respective api's
        this.filterPostFix = url;
        if(this.filterPostFix.includes('search=name')){
          // the search is included for campaigns call;
          console.log(this.getListUrl)
          this.getListUrl=AppConstants.BASE_URL + '/campaigns';
          this.hasCampaigns = false;
          this.hasPayees = true;
        } else {
          this.hasCampaigns = true;
          this.hasPayees = false;
          this.getListUrl=AppConstants.BASE_URL + '/payees';
        }
        this.dataManager.prev_url = url;
        this.is_filter = true;
        this.setUrl();
      },
      (err) => console.log(err)
    );
   }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.searchHits.pipe(debounceTime(600), ).subscribe((res) => {
      if (this.searchText) {
        // this.setUrl();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(FilterConstants.PAYMENT_FILTER_STORAGE_KEY)
        && !this.localStorage.getItem(FilterConstants.PAYMENT_FILTER_COUNT_STORAGE_KEY)) {
        this.setUrl();
      } else  {
        // this.setUrl();
      }
    }
    this.dataService.getPayeeData(this.urlwithLimit).subscribe((res) => {
      try {
             this.getListRes(res);
             if (res.data.length === 0) {
              this.errorMsg = ErrorConstants.payeeErrorMsg[0];
            } else {
              this.errorMsg = null;
            }

      } catch (error) {

        console.log('payee error',error);
        this.getListErr(error);
        this.loadFlag = false;
        this.errorMsg = ErrorConstants.payeeErrorMsg[0];
        this.campaignName = '';
        this.listOfPayees = [];
      }
    })

  }
changeStateOfCampaignName(event) {
this.campaignName = event;
}
  createNewPayee() {
    this.router.navigate(['../merchant-bridge/add-new-payee'])
   }
 protected getListErr(error) {
    this.errorMsg = this.networkErrorHandler(error, ErrorConstants.createInvoiceErrorMsg);
    this.loadFlag = false;

  }
  protected getListRes(res) {
  try {
      if (res.meta.include[0] === 'campaigns') {
      this.listOfPayees = res.data;
      if (this.listOfPayees[0].object==undefined) {
        throw new Error('Payee not found');
      }  else if (res.meta.include[0] === 'payees') {
          this.listOfPayees = res.data[0].payees.data;
          this.campaignName = res.data[0].name;
        }
      } else if (res.meta.include[0] === 'payees')  {
        if (this.listOfPayees[0]?.object==undefined || this.listOfPayees.length==0) {
          throw new Error('Payee not found');
        }  else if (res.meta.include[0] === 'payees') {
          this.listOfPayees=[];
          this.campaignName='';
          if (res.data.length>1) {
            res.data.map(campaign => {
              if (campaign.payees.data.length>0) {
                this.listOfPayees.push(...campaign.payees.data)
                this.campaignName = this.campaignName ===''? this.campaignName.concat(campaign.name) : this.campaignName.concat(', ',campaign.name);
              }
            });
            } else if (res.data.length==1) {
              this.listOfPayees = res.data[0].payees.data;
              this.campaignName = res.data[0].name;
            }
          }
      }

    } catch (error) {
      this.networkErrorHandler(error);
      this.getListErr(error);
      console.log(error,'deyan')
      this.loadFlag = false;
      this.errorMsg = ErrorConstants.payeeErrorMsg[0];
      this.campaignName = '';
      this.listOfPayees = [];
    }
    this.metadata = res.meta.pagination;
    if (res.meta.include.includes('payees')) {

      this.paginationSimulationPayeesFromCampaignsApi = res.meta.pagination;
      this.paginationSimulationPayeesFromCampaignsApi.total = this.listOfPayees.length;
      this.paginationSimulationPayeesFromCampaignsApi.count = this.listOfPayees.length;
      this.metadata = this.paginationSimulationPayeesFromCampaignsApi;
    }
    if (res.data.length === 0) {
      throw new Error('Payee not found');
      this.errorMsg = ErrorConstants.payeeErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
  }
  paginate(paginateExtension ) {
    this.getList(paginateExtension);
    this.loadFlag = true;
  }

  checkdate() {
    if (!this.isNull(this.from_date) && this.from_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  checkdate2() {

    if (!this.isNull(this.to_date) && this.to_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  highlight(value) {
    return Highlight.highlight(value, this.searchText);
  }

  datereport() {
    if ((this.from_date && this.from_date.toString() === 'Invalid Date') || (this.to_date && this.to_date.toString() === 'Invalid Date')) {
      this.showSnackBar('Enter valid date.', 'Ok', 3000);
    } else if ((this.isNullOrUndefined(this.from_date)) || this.isNullOrUndefined(this.to_date)) {
      if ((!this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date)))
        || (this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date)))) {
        this.from = this.from_date;
        this.to = this.to_date;
        // this.setUrl();
      } else {
        return;
      }
    } else if (this.from_date > this.to_date) {
      this.showSnackBar('From date should not exceed ' + this.datePipe.transform(this.to_date, 'MM/dd/yyyy') + '.', 'Ok', 3000);
    } else {
      this.from = (this.isNullOrUndefined(this.from_date) ? '' : (this.datePipe.transform(this.from_date, 'yyyy-MM-dd')));
      this.to = (this.isNullOrUndefined(this.to_date) ? '' : (this.datePipe.transform(this.to_date, 'yyyy-MM-dd')));
      // this.setUrl();
    }
  }

  deletePayee(id: string) {
    if (id === undefined || id === null || id === ''){
      this.payeeId =id ;
    }

    if (this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Payee', message: 'This will permanently remove the payee. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_PAYEES+'/'+id;

        this.deletePayeeApi(url);
        this.loadFlag = true;


      }
    });

  }
  passDeleteResponse(response) {
    this.showSnackBar('Payee Deleted.', 'Ok', 3000);
    window.location.reload();
    this.router.navigate(['merchant-bridge/payee-summary'],{relativeTo: this.route});
  }
  passError(error) {
    this.showSnackBar('Not Deleted.', 'Ok', 3000);
  }

}
