import {Component, DoCheck, HostListener, Inject, OnChanges, OnInit, SimpleChanges, ViewChild, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {
  ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart,
  Router
} from '@angular/router';
import {DataManagerService} from './data/services/DataManager/data-manager.service';
import {BaseComponent} from './abstract/base.component';
import {AppConstants} from './shared/constants/app-constants';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ProfileDialogComponent} from './dialogs/profile-dialog/profile-dialog.component';
import {UpdateContactDialogComponent} from './dialogs/update-contact-dialog/update-contact-dialog.component';
import {AccountModel} from './data/model/AccountModel/account.model';
import {IdleService} from './services/idle.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
  title = 'app';
  sideNavFlag: boolean;
  currentLocation: string;
  currentResolution: number;
  name: string;
  currentUsers: AccountModel[];
  activeUser: AccountModel;
  _getaccount: string;
  serviceName: string;
  currentAccountId: string;
  countdown: number = 0;
  showCountdown: boolean = false;
  private countdownSub: Subscription;
  private resetSub: Subscription;
  @ViewChild('myModalClose') modalClose;
  get userName(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_NAME));
    return currentUser1;
  }

  get userEmail(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_EMAIL));
    return currentUser1;
  }

  constructor(router: Router, dialog: MatDialog, dataManager: DataManagerService, route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any,
              private _route: ActivatedRoute,  private localeService: BsLocaleService,
              private idleService: IdleService,
              private cdr: ChangeDetectorRef) {
    super();
    this.dataManager = dataManager;
    this.router = router;
    this.route = route;
    this.currentLocation = location.pathname;
    this.currentResolution = window.screen.width;
    this.name = null;
    this.serviceName = '/switch';
    this.alertDialog = dialog;
    if (location.pathname !== '/pay/' && location.pathname !== '/pay-ach/') {
      this.toggleSidNav();
    }
    this.currentUsers = [];
    this.activeUser = new AccountModel();
    this._getaccount = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT;
    this.currentAccountId = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    /*this.router.navigate(['/reports/transaction-reports']);*/


  }

  ngOnInit() {
    this.localeService.use('en-gb');

    this.getLogo();
    console.log('called appcomponent');
    // alert(location.pathname);
    if (isPlatformBrowser(this.platformId) &&
      !location.pathname.includes('/pay/') &&
      location.pathname !== '/pay-ach/' &&
      location.pathname !== '/admin') {
      console.log(this.localStorage.getItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN));
      this.dataManager.accessToken = this.localStorage.getItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN);
      this.dataManager.user = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER));
      // this.dataManager.setPermissions(this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_ROLES));
      this.dataManager.accessTokenExists() ? this.dataManager.createHeaderWithUersAccessToken() : '';
      this._route.queryParams.subscribe((params) => {
      });
      console.log('menu on refrsh',
        this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_MENU)
        , JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_MENU)));
      this.dataManager.permissionsArray = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS));
      console.log('permissions  array', this.dataManager.permissionsArray);
      if ((this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_MENU))) {
        console.log('send menu')
        this.dataManager.menuArray = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_MENU));
      }
    }
    // Manage timeout inactivity initialization
    // console.log('### AppComponent ngOnInit');
    this.countdownSub = this.idleService.getCountdownObservable().subscribe((timeLeft) => {
      this.countdown = timeLeft;
      // console.log('### AppComponent countdown', this.countdown, timeLeft);
      this.showCountdown = true;
      this.cdr.detectChanges();
    });
    // Subscribe to reset events to hide the countdown
    this.resetSub = this.idleService.getResetIdleObservable().subscribe(() => {
      this.showCountdown = false;
      this.cdr.detectChanges();
    });
    this.idleService.getLogoutObservable().subscribe(() => {
      this.showCountdown = false;
      this.cdr.detectChanges();
      // console.log('### getLogoutObservable in app component');
      alert('You have been logged out due to inactivity.');
    });
    this.router.events.subscribe(
      (response) => {
      // alert(location.pathname);

        if (response instanceof NavigationStart && location.pathname !== '/admin') {
          this.dataManager.appLoaderFlag(true);
        } else if (response instanceof NavigationEnd) {
          this.dataManager.appLoaderFlag(false);
        } else if (response instanceof NavigationError) {
          this.dataManager.appLoaderFlag(false);
        } else if (response instanceof NavigationCancel) {
          this.dataManager.appLoaderFlag(false);
        }
      },
      (error) => {
      }
    );

  }
  // Manage timeout inactivity initialization
  cancelLogout() {
    // console.log('### cancelLogout');
    this.idleService.cancelLogout();
    this.showCountdown = false;
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (location.pathname !== '/pay/' && location.pathname !== '/pay-ach/') {
      this.toggleSidNav();
    }
  }
  setUserAccounts() {
    this.currentUsers = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_ID));
    if (!this.isNullOrUndefined(this.currentUsers) && this.currentUsers.length > 0) {
      this.setCurrentAccount();
    }
  }
  refreshScreen() {
    this.dataManager.accessTokenExists() ? console.log('accessTokenPresent') : this.routeNavigate('/signin');

  }

  ngDoCheck() {
    if (((location.pathname !== this.currentLocation) || (this.currentResolution !== window.screen.width))
      && (location.pathname !== '/pay/' && location.pathname !== '/pay-ach/')) {
      this.currentLocation = location.pathname;
      this.currentResolution = window.screen.width;
      if (location.pathname !== '/pay/' && location.pathname !== '/pay-ach/') {
        this.toggleSidNav();
      }
    }

    // if (location.pathname === '/customer') {
    //   this.routeNavigate('/customer/customer-list');
    // }
  }

  toggleSidNav() {
    this.sideNavFlag = this.matchUrl('/signin') || this.matchUrl('/register') ||
    this.matchUrl('/loading..') || location.pathname === '/pay/' || location.pathname === '/pay-ach/'
      ? false : (window.screen.width > 767 ? true : false);
  }

  showSideNav() {
    this.sideNavFlag = window.screen.width > 767 ? true : !this.sideNavFlag;
  }
  setCurrentAccount() {
    this.activeUser = this.currentUsers.find(x => x.id === this.currentAccountId);
  }
  switchAccount(id, account_name) {
    console.log('switchaccount', id);
    localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME, JSON.stringify(account_name));
    }


    this._getaccount = AppConstants.BASE_URL + this.serviceName + '/' + id;

    this.router.navigate(['loading..']);
  }

  getLogo() {
    let host = location.hostname;
    if(host.includes('payarc.net')) {
      if(host === 'dashboard.payarc.net') {
        this.dataManager.logoUrl = AppConstants.LOGO_BASE_URL + 'dashboard.png';
        this.dataManager.loginlogo = AppConstants.LOGO_BASE_URL + 'login.png';
      }else {
        host = host.replace('payarc.net', '');
        if(host.includes('testdashboard'))
        {
           this.dataManager.logoUrl = AppConstants.LOGO_BASE_URL + 'dashboard.png';
          this.dataManager.loginlogo = AppConstants.LOGO_BASE_URL + 'login.png';
         }
        else if(host) {
          this.dataManager.logoUrl = AppConstants.LOGO_BASE_URL + host.replace('.', '_') + 'dashboard.png';
          this.dataManager.loginlogo = AppConstants.LOGO_BASE_URL + host.replace('.', '_') + 'login.png';
        }else {
          this.dataManager.logoUrl = AppConstants.LOGO_BASE_URL + 'dashboard.png';
          this.dataManager.loginlogo = AppConstants.LOGO_BASE_URL + 'login.png';
        }
      }
    } else if (host.includes('payarc.io'))
    {
      this.dataManager.logoUrl = AppConstants.LOGO_BASE_URL + 'pre-prod/dashboard.png';
      this.dataManager.loginlogo = AppConstants.LOGO_BASE_URL + 'pre-prod/login.png';
    }
      else {
         this.dataManager.logoUrl = AppConstants.LOGO_BASE_URL + 'dashboard.png';
       this.dataManager.loginlogo = AppConstants.LOGO_BASE_URL + 'login.png';
    }
  }

}





