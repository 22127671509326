import {BaseListComponent} from '../../../abstract/base-list.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class EditCampaignAction extends BaseListComponent {
    //put variables here
    protected getListUrl: string;
    protected editCampaignPostUrl:string;
    protected campaignId:string;

    //put api calls here

    protected editPayeePost(body){
        this.loadFlag = true;
        this.dataManager.patchRequest(this.editCampaignPostUrl, body)
        .subscribe((res)=>{
            this.loadFlag = false;
            this.passEditResponse(res);

            if (res.data.length === 0) {
                this.errorMsg = ErrorConstants.campaignsErrorMsg[0];
              } else {
                this.errorMsg = null;
              }
        },
        error=>{
            this.loadFlag = false;
            this.passError(error);
            this.networkErrorHandler(error);
        });

    }
    protected getPayeeApi(url){
        this.loadFlag = true;
        this.dataManager.getRequest(url,0)
        .subscribe((res)=>{
            this.loadFlag = false;
            this.passGetPayeesResponse(res);
        },
        error=>{
            this.loadFlag = false;
            this.passError(error);
            this.networkErrorHandler(error);
        })

    }


    protected deletePayeeApi(url){
        this.loadFlag = true;
        this.dataManager.deleteRequest(url)
        .subscribe((res)=>{
            this.loadFlag = false;
            this.passDeleteResponse(res);
        },
        error=>{
            this.loadFlag = false;
            this.passError(error);
            this.networkErrorHandler(error);
        })

    }

    protected abstract passEditResponse(response);
    protected abstract passGetResponse(response);
    protected abstract passDeleteResponse(response);
    protected abstract passGetPayeesResponse(response);

    protected abstract passError(error);
}
