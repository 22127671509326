import { Component, OnInit ,PLATFORM_ID,Inject} from '@angular/core';
import {Campaigns, Pagination, Payees, ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';
import { FormBuilder, FormGroup, MinLengthValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MerchantBridgeComponent } from '../../merchant-bridge/merchant-bridge.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { NewCampaignCreationAction } from './new-campaign-creation.action';
import { isPlatformBrowser } from '@angular/common';
import { AppConstants } from '../../../shared/constants/app-constants';
import {Highlight} from '../../../shared/highlights/highlight';
import { ErrorConstants } from '../../../shared/constants/error-constants';
import { MessageDialogComponent } from '../../../dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-new-campaign-creation',
  templateUrl: './new-campaign-creation.component.html',
  styleUrls: ['./new-campaign-creation.component.css']
})
export class NewCampaignCreationComponent extends NewCampaignCreationAction implements OnInit {
  urlwithLimit: string;
  loadFlag = false;
  chargeList: ResponseWrapper<any>;
  addCampaign: FormGroup;
  buttonText: string='Submit Request';
  load: boolean;
  failureMessage: string;
  _service_name = '/campaigns';
  _service_name_campaign = '/campaigns';
  topErrorMessage: string;
  keys1: String[];
  keys: String[];
  // payees:ResponseWrapper<any>;
  metadata:Pagination;
  searchText: string;
  flagButton: boolean;
  campaignApiPostUrl: string;
  redirect_link_edit: string;
  redirect_link_details: string;
  actionActive: boolean;
  campaignId:string;
  listofPayees:ResponseWrapper<Payees>;
  getListPayeesUrl: string;



  constructor(
    router:Router,
    route:ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    private fb:FormBuilder,
    dataManager: DataManagerService,
    snackBar: MatSnackBar,
    private dialog: MatDialog) {
    super();
    this.limit = 10;
    this.getListUrl = AppConstants.BASE_URL + this._service_name;
    this.campaignApiPostUrl = AppConstants.BASE_URL + this._service_name_campaign;
    this.getListPayeesUrl = AppConstants.BASE_URL + '/payees';
    this.router = router;
    this.dataManager = dataManager;
    this.snackBar = snackBar;
    this.topErrorMessage = null;
    this.urlwithLimit = this.getListUrl  + '?limit=' +this.limit  + '&page=1'+ '&orderBy=created_at&sortedBy=desc'+'&include=payees';
    this.redirect_link_edit = '/merchant-bridge/edit-campaign';
    this.redirect_link_details = '/merchant-bridge/campaign-details';
    this.actionActive = false;
    this.listofPayees = new ResponseWrapper<Payees>();

   }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.campaignApiCall(this.urlwithLimit);
        console.log('first call for list');
        this.getListPayee(this.getListPayeesUrl);

    }


    this.addCampaign = this.fb.group({
      name: ['',[<any>Validators.required,Validators.maxLength(50),Validators.minLength(3)]],
      description: ['', [<any>Validators.required,Validators.minLength(3)]],
      notes: [''],

    });

  }


  passCampaignPostResponse(response) {
    this.topErrorMessage = response.errors;
    if (this.topErrorMessage) {
      this.keys = Object.keys(this.topErrorMessage);
      this.keys1 = Object.values(this.topErrorMessage);
      let i;
      let j;
      const name = ['name', 'account_id', 'agent_id', 'status'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + '1').style.display = 'block';
          }
        }
      }
    } else {
      this.errorMsg = response.message;
    }
    this.showSnackBar('Created', 'OK', 3000);
    this.campaignApiCall(this.urlwithLimit);
  }
  highlight(value) {

    return Highlight.highlight(value, this.searchText);
  }
  cancelCampaignNew() {
    this.backToPage();
  }

  backToPage() {
      this.router.navigate(['../merchant-bridge/campaign-summary'], {relativeTo: this.route});

  }
  submitCampaignNew() {

    type Campaign = {
      campaignName: string;
      campaignDescription: string;
      campaignNotes?: string;
      agent_id?: string;
    };
    let bodyPost=this.addCampaign.value as Campaign;
    // this.addCampaign.value.status='inactive';

    this.campaignApiPost(bodyPost);

    this.showSnackBar('Created Campaign', 'OK', 3000);


  }

  protected passCampaignResponse(payees){
      this.loadFlag = false;
      this.failureMessage = payees.data.failure_message;
      this.payees = payees;
      this.metadata = payees.meta.pagination;

  };
  protected getListRes(res) {
    this.loadFlag = false;
    this.payees = res;
    this.metadata = res.meta.pagination;
    if (res.data.length === 0) {
      this.errorMsg = ErrorConstants.campaignsErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
  }
  paginate(paginateExtension) {
    this.getList(paginateExtension);

  }
  protected passCampaignError(error){


    // this.payeeApiCall(this.getListUrl);
    this.topErrorMessage = error.errors;
    if (this.topErrorMessage) {
      this.keys = Object.keys(this.topErrorMessage);
      this.keys1 = Object.values(this.topErrorMessage);
      let i;
      let j;
      const name = ['name', 'account_id', 'agent_id', 'status'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + '1').style.display = 'block';
          }
        }
      }
    } else {
      this.errorMsg = error.message;
    }
  };

  deleteCampaign(id: string) {

    if (this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Campaign', message: 'This will permanently remove the campaign. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CAMPAIGNS+'/'+id;

        this.deleteCampaignApi(url);
        this.loadFlag = true;

      }
    });
  }

  protected passDeleteResponse(response: any) {
    this.paginate(this.metadata.current_page);
    this.campaignApiCall(this.urlwithLimit);
    this.snackBar.open('Deleted','OK',{duration:3000});

  }

  protected passPayeesResponse(response: any) {
    this.listofPayees = response.data;

  }
  protected passDeleteError(error: any) {
    this.errorMsg = this.networkErrorHandler(error.error);
    this.passCampaignError(error);

  }
  print(obj) {
    console.log(obj);
  }
}
