<div class="wrapper" xmlns="http://www.w3.org/1999/html">
  <div class="row" *ngIf="matchUrl('/loading..')">
    <div class="col-md-12">
      <mat-card class="container-card"  [style.height]="'100vh'" [style.margin-top]="'0'">
        <div class="detail-loader-container" *ngIf="!errorMsg" [style.margin-top]="'20%'" style="width: 7%; margin-left: 47%">
          <img class="detail-loader" src="../../../../assets/images/loading.svg">
        </div>
      </mat-card>
    </div>
  </div>
  <app-manual-invoice-payment *ngIf="matchUrl('/pay/')"></app-manual-invoice-payment>
  <app-manual-ach-invoice-payment *ngIf="matchUrl('/pay-ach/')" ></app-manual-ach-invoice-payment>
<div *ngIf="!matchUrl('/loading..') && !matchUrl('/pay/') && !matchUrl('/pay-ach/')">

  <div *ngIf="showCountdown" class="countdown-container" style="position: relative; z-index: 100">
    <p class="countdown-message">Logging out due to inactivity in {{ countdown }} seconds...</p>
    <button class="cancel-button" (click)="cancelLogout()">Cancel Logout</button>
  </div>
  <app-nav-drawer class="row nav_drawer"  (menuClicked)="showSideNav()"   [toggleSideNav]="sideNavFlag" ></app-nav-drawer>

  </div>
</div>
