import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, NavigationExtras, ParamMap, Router, RoutesRecognized} from '@angular/router';
// import {SiginAction} from './sigin.action';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ManualInvoicePaymentAction} from './manual-invoice-payment.action';
import {InvoicesModel} from '../../data/model/InvoicesModel/invoices.model';
import {switchMap} from 'rxjs/operators';
import {CreditCard, CreditCardValidators} from 'angular-cc-library';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardModel } from '../../data/model/CardModel/card.model';

@Component({
  selector: 'app-manual-invoice-payment',
  templateUrl: './manual-invoice-payment.component.html',
  styleUrls: ['./manual-invoice-payment.component.css']
})
export class ManualInvoicePaymentComponent extends ManualInvoicePaymentAction implements OnInit {
  public manualInvoiceForm : FormGroup;
  @Input() customerCard: CardModel;
  @Output() onCustomerCardChange = new  EventEmitter<CardModel>();
  @Output() onCvvError = new EventEmitter<boolean>();
  submitted = false;
  cvcError: boolean;
  email: string;
  password: string;
  failureMessage: string;
  forgotpassword: boolean;
  signin: boolean;
  forgot: string;
  _downloadInvoice: string;
  _downloadReceipt: string;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;
  Location: string;
  selectedChargeId: string;
  ReceievedInvoice: InvoicesModel;
  type: string;
  load: boolean;
  states = AppConstants.stateList;
  countries = AppConstants.countryList;
  card_img_obj = {
    'discover': '../../../../assets/images/disc.png', 'visa': '../../../../assets/images/visa.png',
    'amex': '../../../../assets/images/amex.png', 'mastercard': '../../../../assets/images/master.png',
    'jcb': '../../../../assets/images/jcb.png', 'default': '../../../../assets/images/card-default.png'
  };
  cvvoptional: any;
  addresopt: any;
  constructor(dataManager: DataManagerService, snackBar: MatSnackBar,
              router: Router, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any,
              private _route: ActivatedRoute, private fb: FormBuilder) {
    super();
    this.snackBar = snackBar;
    this.signin = true;
    this.forgotpassword = false;
    this.forgot = '/forgot';
    this.dataManager = dataManager;
    this.router = router;
    this.ReceievedInvoice = new InvoicesModel();
    this.customerCard = new CardModel();
    this.customerCard.country = 'US';
    this.customerCard.state = '';
    this.router.events.subscribe(val => {

      if (val instanceof RoutesRecognized) {
        const id = val.state.root.firstChild.params['id'];
        this._getManualInvoiceUrl = AppConstants.BASE_URL + '/pay/' + id;
        this._createCardUrl = AppConstants.BASE_URL + '/pay/' + id;
        this._downloadInvoice = AppConstants.BASE_URL + '/pay/' + id + '/invoice';
        this.getManualInvoice(this._getManualInvoiceUrl);
        this._downloadReceipt = AppConstants.BASE_URL + '/pay/' + id + '/receipt';
      }
    });
  }
  ngOnInit() {
    this.manualInvoiceForm = this.fb.group({
      creditCard: ['', [<any>Validators.required, Validators.maxLength(23), <any>CreditCardValidators.validateCCNumber]],
      expDate: ['', [<any>Validators.required, <any>CreditCardValidators.validateExpDate, <any>Validators.minLength(4)]],
      cvc: ['',[<any>Validators.minLength(3), <any>Validators.maxLength(4)]],
      address_line1: ['', [<any>Validators.maxLength(200)]],
      city: [''],
      zip: ['', [Validators.maxLength(10), Validators.minLength(2)]],
      state: [''],
      country: [''],
    })
  }

  get f() { return this.manualInvoiceForm.controls;
  }

  checkCvc(cvv) {
    // merchants complain, removing for test
    return;
    if (cvv === '000' || cvv === '0000') {
      this.cvcError = true;
    } else {
      this.cvcError = false;
    }
    this.onCvvError.emit(this.cvcError);
  }
  onCardDataChange() {
    this.onCustomerCardChange.emit(this.customerCard);
  }
  dlInvoice() {
    window.open(this._downloadInvoice);
  }
  dlreceipt() {
    window.open(this._downloadReceipt);
  }
  payInvoice() {
    this.submitted = true;
    if(this.manualInvoiceForm.status == 'INVALID') {
      return
    } else {
    this.failureMessage = null;
    const cardBody = {};

    cardBody['card_number'] = AppConstants.getValidCardNo(this.manualInvoiceForm.value.creditCard.replace(/\D+/g, ''), this.type);
    const x = this.manualInvoiceForm.value.expDate.split('/');
    cardBody['exp_month'] = x[0].replace(/\D+/g, '');
    if (x[1].replace(/\D+/g, '').length > 4) {
      cardBody['exp_year'] = x[1].replace(/\D+/g, '').slice(0, -1);
    } else {
      cardBody['exp_year'] = x[1].replace(/\D+/g, '');
    }
    // if (this.cvvoptional === 0) {
    //   cardBody['cvv'] = this.manualInvoiceForm.value.cvc ? this.manualInvoiceForm.value.cvc.replace(/\D+/g, '') : null;
    // }
    cardBody['cvv'] = this.manualInvoiceForm.value.cvc ? this.manualInvoiceForm.value.cvc.replace(/\D+/g, '') : console.log(this.manualInvoiceForm.value.cvc);
    cardBody['address_line1'] = this.manualInvoiceForm.value.address_line1 ? this.manualInvoiceForm.value.address_line1 : '';
    cardBody['country'] = this.manualInvoiceForm.value.country ? this.manualInvoiceForm.value.country : '';
    cardBody['state'] = this.manualInvoiceForm.value.state ? this.manualInvoiceForm.value.state : '';
    cardBody['city'] = this.manualInvoiceForm.value.city ? this.manualInvoiceForm.value.city : '';
    cardBody['zip'] = this.manualInvoiceForm.value.zip ? this.manualInvoiceForm.value.zip : '';
    console.log('adad', cardBody);
    this.cardApiCall(cardBody);
    this.load = true;
  }
}
  getCardType(event) {
    this.type = CreditCard.cardType(event.target.value);
  }
  protected passManualInvoice(res: any) {

    if (typeof res.data.subscription !=='undefined' ?res.data.subscription.data.payment_type === 'ach':false) {
      console.log('sign_in response', res.data.subscription.data.payment_type);
      this.router.navigate(['/pay-ach/'+res.data.id],{state:res});
    }

    this.loadFlag = false;
    this.load = false;
    this.ReceievedInvoice = res.data;
    this.cvvoptional = res.data.cvv_optional;
    this.addresopt = res.data.address_optional;
    if(this.cvvoptional === 0) {
      this.manualInvoiceForm.controls['cvc'].setValidators([<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(4)]);
      this.manualInvoiceForm.controls['cvc'].updateValueAndValidity();
      } else  {
        this.manualInvoiceForm.controls['cvc'].clearValidators();
        this.manualInvoiceForm.controls['cvc'].updateValueAndValidity();
      }
    if(this.addresopt === 0) {
      this.addressRequired();
    } else {
      this.addressNotRequired();
    }

    console.log('sign_in response', res);
  }
  protected passManualInvoiceError(error: ApiErrorModel) {
    this.loadFlag = false;
    console.log(error);
    this.showSnackBar(error.message, 'Ok', 3000);
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    let i;
    let j;
    const name = ['email', 'password'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
  protected passCardResponse(charge) {
    if (this.isNullOrUndefined(charge.data.failure_message)) {
      this.loadFlag = true;
      this.getManualInvoice(this._getManualInvoiceUrl);
    } else {
      this.loadFlag = false;
      this.load = false;
      this.failureMessage = charge.data.failure_message;
    }
    console.log(charge);
  }
  protected passCardError(error) {
    this.loadFlag = false;
    this.load = false;
    console.log(error);
    this.showSnackBar(error.message, 'Ok', 3000);
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    let i;
    let j;
    const name = ['street' , 'city', 'state' , 'zip', 'country'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
  numericOnly(event) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === '-') // dash
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  addressRequired() {
    this.manualInvoiceForm.controls['address_line1'].setValidators([<any>Validators.required,<any>Validators.maxLength(200)]);
    this.manualInvoiceForm.controls['address_line1'].updateValueAndValidity();
    this.manualInvoiceForm.controls['city'].setValidators([<any>Validators.required]);
    this.manualInvoiceForm.controls['city'].updateValueAndValidity();
    this.manualInvoiceForm.controls['state'].setValidators([<any>Validators.required]);
    this.manualInvoiceForm.controls['state'].updateValueAndValidity();
    this.manualInvoiceForm.controls['country'].setValidators([<any>Validators.required]);
    this.manualInvoiceForm.controls['country'].updateValueAndValidity();
    this.manualInvoiceForm.controls['zip'].setValidators([<any>Validators.required, <any>Validators.maxLength(10), Validators.minLength(2)]);
    this.manualInvoiceForm.controls['zip'].updateValueAndValidity();

  }

  addressNotRequired() {
    this.manualInvoiceForm.controls['address_line1'].setValidators([<any>Validators.maxLength(200)]);
    this.manualInvoiceForm.controls['address_line1'].updateValueAndValidity();
    this.manualInvoiceForm.controls['city'].clearValidators();
    this.manualInvoiceForm.controls['city'].updateValueAndValidity();
    this.manualInvoiceForm.controls['state'].clearValidators();
    this.manualInvoiceForm.controls['state'].updateValueAndValidity();
    this.manualInvoiceForm.controls['country'].clearValidators();
    this.manualInvoiceForm.controls['country'].updateValueAndValidity();
    this.manualInvoiceForm.controls['zip'].setValidators([<any>Validators.maxLength(10), Validators.minLength(2)]);
    this.manualInvoiceForm.controls['zip'].updateValueAndValidity();

  }
}
